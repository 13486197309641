import React from 'react';

import * as styles from './section.module.css';
import { cn } from '../../lib/helpers';
import { GatsbyImage } from 'gatsby-plugin-image';
import { hero } from '../layout/header.module.css';

// cms saves only the hex values so we gotta map back to our css var color names
export const colorsToClasses = {
  white: 'white',
  '#000000': 'black',
  '#08176B': 'ugBlue',
  '#0A1F8F': 'ugLightBlue',
  '#00A87C': 'ugGreen',
  '#75BC22': 'ugLimeGreen',
  '#FFCE1F': 'ugYellow',
  '#F5FBF9': 'ugBlueGray',
  '#666666': 'ugDarkGray',
  '#FAFAFA': 'ugGrayLightest',
  '#A7A9AB': 'ugGrayLighter',
  '#D1D3D4': 'ugGrayLighterer',
  '#818284': 'ugGrayLight',
};

const paddingTopSizesToClasses = {
  SM: 'paddingTopSM',
  MD: 'paddingTopMD',
  LG: 'paddingTopLG',
  NONE: 'paddingTopNone',
};

const paddingBottomSizesToClasses = {
  SM: 'paddingBottomSM',
  MD: 'paddingBottomMD',
  LG: 'paddingBottomLG',
  NONE: 'paddingBottomNone',
};

/**
 * Section is like a full width row that contains containers to center inside it
 */

const Section = ({
  color = 'white',
  children,
  alignment,
  flush,
  flushSides,
  topBorder,
  bottomPadding,
  topPadding,
  id = null,
  noPaddingTop,
  alignReset,
  noPadding,
  noPaddingBottom,
  backgroundImage,
}) => {
  // console.log('bottomPadding: ', bottomPadding);
  // console.log('topPadding: ', topPadding);
  // prefer classes if they exist
  const colorClass = colorsToClasses[color] || color;
  const colorStyles = !colorClass
    ? {
        backgroundColor: color,
        // use black text for specific colors if no class set
        color:
          color === 'white' || ['#F5FBF9', '#FAFAFA', '#FFCE1F', '#D1D3D4'].includes(color)
            ? 'var(--color-black)'
            : 'var(--color-white)',
      }
    : {};
  return (
    <section
      style={colorStyles}
      className={cn(
        styles.root,
        topBorder && styles.topBorder,
        bottomPadding && styles[paddingBottomSizesToClasses[bottomPadding]],
        topPadding && styles[paddingTopSizesToClasses[topPadding]],
        styles[colorClass],
        flush && styles.flush,
        flushSides && styles.flushSides,
        alignment === 'center' && styles.center,
        alignReset && styles.alignReset,
        noPaddingTop && styles.noPaddingTop,
        noPadding && styles.noPadding,
        noPaddingBottom && styles.noPaddingBottom
      )}
      id={id}
    >
      {backgroundImage && (
        <GatsbyImage
          image={backgroundImage.asset?.gatsbyImageData}
          loading="eager"
          className={hero}
          style={{ height: 0 }}
          alt={backgroundImage.alt ? backgroundImage.alt : 'section background image'}
        />
      )}
      <div className={styles.content}>{children}</div>
    </section>
  );
};

export default Section;
